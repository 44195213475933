import aiImagePng from '@assets/images/clubs/ai/ai.png';
import aiImageWebP from '@assets/images/clubs/ai/ai.webp';
import aiImageHoverPNG from '@assets/images/clubs/ai/ai_hover.png';
import aiImageHoverWebP from '@assets/images/clubs/ai/ai_hover.webp';
import designImagePng from '@assets/images/clubs/design/design.png';
import designImageWebP from '@assets/images/clubs/design/design.webp';
import designImageHoverPNG from '@assets/images/clubs/design/design_hover.png';
import designImageHoverWebP from '@assets/images/clubs/design/design_hover.webp';
import gameImagePng from '@assets/images/clubs/gamedev/gamedev.png';
import gameImageWebP from '@assets/images/clubs/gamedev/gamedev.webp';
import gameImageHoverPNG from '@assets/images/clubs/gamedev/gamedev_hover.png';
import gameImageHoverWebP from '@assets/images/clubs/gamedev/gamedev_hover.webp';
import hackImagePNG from '@assets/images/clubs/hack/hack.png';
import hackImageWebP from '@assets/images/clubs/hack/hack.webp';
import hackImageHoverPNG from '@assets/images/clubs/hack/hack_hover.png';
import hackImageHoverWebP from '@assets/images/clubs/hack/hack_hover.webp';
import roboticsImagePng from '@assets/images/clubs/robotics/robotics.png';
import roboticsImageWebP from '@assets/images/clubs/robotics/robotics.webp';
import roboticsImageHoverPNG from '@assets/images/clubs/robotics/robotics_hover.png';
import roboticsImageHoverWebP from '@assets/images/clubs/robotics/robotics_hover.webp';

import { type Club } from './types';

export const images: Record<Club, {
  png: string
  webp: string
  pngHover: string
  webpHover: string
}> = {
  robotics: {
    png: roboticsImagePng,
    webp: roboticsImageWebP,
    pngHover: roboticsImageHoverPNG,
    webpHover: roboticsImageHoverWebP
  },
  ai: {
    png: aiImagePng,
    webp: aiImageWebP,
    pngHover: aiImageHoverPNG,
    webpHover: aiImageHoverWebP
  },
  design: {
    png: designImagePng,
    webp: designImageWebP,
    pngHover: designImageHoverPNG,
    webpHover: designImageHoverWebP
  },
  game: {
    png: gameImagePng,
    webp: gameImageWebP,
    pngHover: gameImageHoverPNG,
    webpHover: gameImageHoverWebP
  },
  hack: {
    png: hackImagePNG,
    webp: hackImageWebP,
    pngHover: hackImageHoverPNG,
    webpHover: hackImageHoverWebP
  }
};
