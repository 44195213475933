import React from 'react';

import { type RouteObject } from 'react-router-dom';

import { Team } from '@pages/Team';

import { TEAM_ROUTE } from './constants';

export const teamRoutes: RouteObject[] = [
  {
    path: TEAM_ROUTE.team.path,
    element: (
        <Team />
    )
  }
];
