import styled from 'styled-components';

import { Flex, Heading as _Heading, Image as _Image } from '@uiKit';

import { Card } from '@component/Card';
import { type ClubCardProps } from '@component/ClubCard/types';

import { animatedBorder } from '../../styles/animatedBorder';

const ImageWrapper = styled.div`
  position: relative;
  width: 300px;
  height: 300px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 100%;
  }
`;

const Image = styled(_Image)`
  position: absolute;
`;

const ImageFront = styled(Image)`
  position: absolute;
  top: 0;
  transition: opacity .25s linear;
`;

const Main = styled(Flex)``;

const Heading = styled(_Heading)``;

const ClubCard = styled(Card)<{
  $dir: ClubCardProps['dir']
  $color: ClubCardProps['color']
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: clamp(${({ theme }) => theme.spacings.x16}px, 4vw, ${({ theme }) => theme.spacings.x36}px);
  height: 300px;
  direction: ${({ $dir }) => $dir};
  border: 1px solid rgba(255, 255, 255, .1);

  &:hover {
    ${({ $color }) => animatedBorder($color)};

    ${ImageFront} {
      opacity: 0;
    }

    ${Heading} {
      background: ${({ $color }) => $color};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: ${({ theme }) => theme.spacings.x64}px;
    height: 440px;
  }
`;

export const Styled = {
  ClubCard,
  Main,
  Image,
  ImageWrapper,
  ImageFront,
  Heading
};
