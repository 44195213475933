import styled, { css } from 'styled-components';

import { type AvatarProps, AvatarSizes } from './types';

const getAvatarSizeStyleCSS = {
  [AvatarSizes.SMALL]: css`
    width: 30px;
    height: 30px;
  `,
  [AvatarSizes.DEFAULT]: css`
    width: 48px;
    height: 48px;
  `,
  [AvatarSizes.LARGE]: css`
    width: 64px;
    height: 64px;
  `
};

const Image = styled.img<{
  $size: AvatarProps['size']
}>`
  border-radius: 100%;
  object-fit: cover;

  ${({ $size }) => $size && getAvatarSizeStyleCSS[$size]}
`;

export const StyledAvatar = {
  Image
};
