import React, { type FC, type PropsWithChildren, useRef } from 'react';

import { useTheme } from 'styled-components';

import { useAnimatedData } from '@hooks/useAnimatedData';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Styled } from '@pages/Home/components/TeamSection/components/Team/styled';

export const Team: FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  useAnimatedData(ref, { threshold: 0.5 });

  return (
    <Styled.Wrapper ref={ref} tabIndex={0}>
      {!isMobile && <Styled.ShadowStart />}
      <Styled.ScrollElement>{children}</Styled.ScrollElement>
      <Styled.ScrollElement>{children}</Styled.ScrollElement>
      {!isMobile && <Styled.ShadowEnd />}
    </Styled.Wrapper>
  );
};
