import { type ChangeEvent } from 'react';

export enum RadioGroupDirection {
  ROW = 'row',
  COLUMN = 'column'
}

export interface RadioGroupProps {
  children: JSX.Element[] | JSX.Element
  direction?: RadioGroupDirection
  gap?: number
  name: string
  value?: string | number
  onChange?: (value: string, e: ChangeEvent<HTMLInputElement>) => void
}
