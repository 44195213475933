import styled from 'styled-components';

import { Icon, Text } from '@uiKit';

import { Images } from '@uiKit/Icon/constants/images';
import { IconSize } from '@uiKit/Icon/types';

import { Card } from '@component/Card';

const Block = styled(Card)`
  position: relative;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacings.x24}px;
  min-height: 180px;
  box-sizing: border-box;
  width: 100%;
  transition: all .125s ease-in;
  border: 1px solid rgba(255, 255, 255, .1);
`;

const SocialName = styled(Text)``;

const SocialUsername = styled(Text)``;

const GoArrow = styled(Icon).attrs({ image: Images.GO_ARROW, size: IconSize.X24 })`
  position: absolute;
  bottom: ${({ theme }) => theme.spacings.x24}px;
  right: ${({ theme }) => theme.spacings.x24}px;
  transition: all .25s linear;
`;

const Link = styled.a`
  text-decoration: none;
  outline: none;
  
  &:hover ${Block}, &:focus-visible ${Block} {
    border-color: white;
    transform: scale(1.05);
    
    ${GoArrow} {
      transform: rotate(45deg) scale(1.2);
    }
  }
`;

export const Styled = {
  Block,
  GoArrow,
  Link,
  SocialName,
  SocialUsername
};
