import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { API_CONFIG } from '../../../../config/api';

import { type ProjectsRequest, type ProjectsResponse } from './types';

export const getProjects = async (
  data?: ProjectsRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<ProjectsResponse>> => {
  const { baseUrl = '' } = options ?? {};
  const { count, club, badge } = data ?? {};

  return await request({
    url: '/api/projects?populate=*',
    baseUrl,
    method: HTTPMethods.GET,
    headers: {
      Authorization: API_CONFIG.API_TOKEN
    },
    params: {
      'pagination[limit]': count,
      'filters[club][$eq]': club,
      'filters[badge][$eq]': badge
    }
  });
};
