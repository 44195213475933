import React, { type FC } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Flex } from '@uiKit';

import { Images } from '@uiKit/Icon/constants/images';

import { IconLink } from '@component/IconLink';
import { IconLinkSize } from '@component/IconLink/types';
import { Logo } from '@component/Logo';
import { ROUTES } from '@router/routes/constants';

import { DesktopHeaderNav } from './components';
import { Styled } from './styled';

export const DesktopHeader: FC = () => {
  const theme = useTheme();

  return (
    <Styled.Header>
      <RouterLink to={ROUTES.home.path}>
        <Logo />
      </RouterLink>
      <DesktopHeaderNav />
      <Flex gap={theme.spacings.x8}>
        <IconLink to='https://vk.com/itatmisis' icon={Images.VK} size={IconLinkSize.LG} />
        <IconLink to='https://t.me/itatmisis' icon={Images.TG} size={IconLinkSize.LG} />
      </Flex>
    </Styled.Header>
  );
};
