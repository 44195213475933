import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Footer } from '@component/Footer';
import { Header } from '@component/Header/Header';
import { Layout } from '@layouts/Layout';

import { type PageLayoutProps } from './types';

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
      <Layout {...props} header={<Header />} footer={<Footer />} backgroundColor={theme.colors.base.background}>{children}</Layout>
  );
};
