import React, { type FC, type KeyboardEvent, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Flex, Link } from '@uiKit';

import { FlexAlignItems } from '@uiKit/Flex/types';
import { LinkSize } from '@uiKit/Link/types';

import { ROUTES } from '@router/routes/constants';

import { Styled } from './styled';

export const MobileHeader: FC = () => {
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);

  const onClick = (e?: any) => {
    e.preventDefault();

    setOpen((prev) => !prev);
    document.body.classList.toggle('stopScroll');
  };

  const onKeyDown = (e: KeyboardEvent) => {
    e.preventDefault();

    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  const onClickLink = () => {
    setOpen(false);
    document.body.classList.remove('stopScroll');
  };

  return (
    <>
      <Styled.Header>
        <Styled.ButtonBlock data-open={isOpen}>
          <Styled.Button aria-label='' onClick={onClick} onKeyDown={onKeyDown} role='button' tabIndex={0}>
            <Styled.InputButton type='checkbox' checked={isOpen}/>
            <Styled.ButtonLine />
            <Styled.ButtonLine />
            <Styled.ButtonLine />
          </Styled.Button>
        </Styled.ButtonBlock>
      </Styled.Header>
      <Styled.Wrapper data-open={isOpen}>
        <Styled.Menu data-open={isOpen}>
          <Styled.Nav>
            <Styled.LinkList>
              <Styled.LinkListItem>
                <Link onClick={onClickLink} isUnderline size={LinkSize.MD} as={NavLink} to={ROUTES.home.path + '#about'}>О нас</Link>
              </Styled.LinkListItem>
              <Styled.LinkListItem>
                <Link onClick={onClickLink} isUnderline size={LinkSize.MD} as={NavLink} to={ROUTES.home.path + '#partners'}>Партнеры</Link>
              </Styled.LinkListItem>
              <Styled.LinkListItem>
                <Link onClick={onClickLink} isUnderline size={LinkSize.MD} as={NavLink} to={ROUTES.home.path + '#projects'}>Проекты</Link>
              </Styled.LinkListItem>
              <Styled.LinkListItem>
                <Link onClick={onClickLink} isUnderline size={LinkSize.MD} as={NavLink} to={ROUTES.home.path + '#clubs'}>Клубы</Link>
              </Styled.LinkListItem>
            </Styled.LinkList>
          </Styled.Nav>
          <Flex fullHeight alignItems={FlexAlignItems.FLEX_END}>
            <Styled.Copyright color={theme.colors.base.grey[700]}>@2023. ITAM</Styled.Copyright>
          </Flex>
        </Styled.Menu>
      </Styled.Wrapper>
    </>
  );
};
