import styled from 'styled-components';

const List = styled.ul`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacings.x16}px;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  list-style-type: none;
  opacity: 0;
  transform: scale(.75);
  transition: 1s;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr;
  }

  &[data-animated='true'] {
    opacity: 1;
    transform: scale(1);
  }
`;

const Item = styled.li``;

export const Styled = {
  List,
  Item
};
