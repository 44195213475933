import React, { type ChangeEvent, Children, cloneElement, type FC } from 'react';

import { Styled } from '@component/RadioGroup/styled';
import { RadioGroupDirection, type RadioGroupProps } from '@component/RadioGroup/types';

export const RadioGroup: FC<RadioGroupProps> = ({ children, direction = RadioGroupDirection.ROW, gap, name, onChange, value }) => {
  return (
    <Styled.Fieldset $gap={gap} $direction={direction}>
      {Children.map(children, (child) => cloneElement(child, { name, checked: child.props.value === value, onChange: (e: ChangeEvent<HTMLInputElement>) => { onChange?.(e.target.value, e); } }))}
    </Styled.Fieldset>
  );
};
