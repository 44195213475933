import styled from 'styled-components';

import { Text } from '@uiKit';

const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radiuses.df}px;
  background: ${({ theme }) => theme.colors.base.grey[800]};
  width: 48px;
  height: 48px;
  transition: .2s;

  &[data-open='true'] {
    background: ${({ theme }) => theme.colors.base.background};
  }
`;

const ButtonLine = styled.span``;

const InputButton = styled.input`
  display: none;
`;

const Button = styled.label`
  display: flex;
  flex-direction: column;
  width: 30px;
  cursor: pointer;
  
  ${ButtonLine} {
    background: #fff;
    border-radius: 10px;
    height: 2px;
    margin: 3px 0;
    transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  ${ButtonLine}:nth-of-type(1) {
    width: 50%;
  }

  ${ButtonLine}:nth-of-type(2) {
    width: 100%;
  }

  ${ButtonLine}:nth-of-type(3) {
    width: 75%;
  }
  
  ${InputButton}:checked ~ ${ButtonLine}:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(3px, 0px);
  }

  ${InputButton}:checked ~ ${ButtonLine}:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  ${InputButton}:checked ~ ${ButtonLine}:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(14px, -4px) rotatez(45deg);
  }
`;

const Header = styled.header`
  position: sticky;
  padding: ${({ theme }) => theme.spacings.x16}px calc(var(--layout-padding-side));
  max-width: calc(var(--max-width) + 2px);
  width: 100%;
  box-sizing: border-box;
  top: 0;
  z-index: 5;
`;

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  
  &[data-open='true'] {
    width: 100%;
    height: 100vh;
  }
`;

const Nav = styled.nav`
  margin-top: calc(80px + ${({ theme }) => theme.spacings.x64}px);
`;

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: ${({ theme }) => theme.spacings.x36}px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
`;

const LinkListItem = styled.li`
  a {
    opacity: 0;
    transform: translateX(-100px);
    font-size: 3rem;
  }
`;

const Copyright = styled(Text)`
  opacity: 0;
  transition: all .25s linear;
`;

const Menu = styled.menu`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  transform: scale(1);
  transition: transform .25s linear;
  padding: ${({ theme }) => theme.spacings.x16}px;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  box-sizing: border-box;
  
  &:after {
    content: '';
    position: absolute;
    top: 16px;
    left: 16px;
    border-radius: ${({ theme }) => theme.radiuses.df}px;
    background: ${({ theme }) => theme.colors.base.background};
    width: 48px;
    height: 48px;
    transform: scale(1);
    z-index: -1;
    transition: transform .35s linear;
  }
  
  &[data-open='true'] {
    visibility: visible;
    opacity: 1;
    height: 100%;
    width: 100%;
    
    &:after {
      transform: scale(80);
    }
    
    ${Copyright} {
      transition-delay: .75s;
      opacity: 1;
    }
    
    ${LinkList} {
      ${LinkListItem}:nth-child(1) a {
        animation: animationLink .2s ease-in-out forwards;
        animation-delay: .6s;
      }

      ${LinkListItem}:nth-child(2) a {
        animation: animationLink .2s ease-in-out forwards;
        animation-delay: .4s;
      }

      ${LinkListItem}:nth-child(3) a {
        animation: animationLink .2s ease-in-out forwards;
        animation-delay: .2s;
      }

      ${LinkListItem}:nth-child(4) a {
        animation: animationLink .2s ease-in-out forwards;
      }
      
      @keyframes animationLink {
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
`;

export const Styled = {
  Header,
  Wrapper,
  Menu,
  Button,
  InputButton,
  ButtonLine,
  ButtonBlock,
  Nav,
  LinkList,
  LinkListItem,
  Copyright
};
