import React, { type FC, useEffect, useRef, useState } from 'react';

import { useTheme } from 'styled-components';

import { Spacer, Text } from '@uiKit';

import { TextAlign, TextSize } from '@uiKit/Text/types';

import miaLogo from '@assets/images/miaP.svg';
import misisLogo from '@assets/images/misisLogoP.svg';
import myProfITLogo from '@assets/images/myProfITP.svg';
import sberLogo from '@assets/images/sberLogoP.svg';
import skoltechLogo from '@assets/images/skoltechLogoP.svg';
import yandexLogo from '@assets/images/yandexLogoP.svg';
import { useAnimatedData } from '@hooks/useAnimatedData';
import { Partners } from '@pages/Home/components/MainSection/components';
import { Styled } from '@pages/Home/components/MainSection/styled';
import { type MainSectionProps } from '@pages/Home/components/MainSection/tpyes';

export const MainSection: FC<MainSectionProps> = ({ title, subtitle }) => {
  const theme = useTheme();
  const ref = useRef<HTMLHeadingElement>(null);
  const [text, setText] = useState('');
  const [caretIndex, setCaretIndex] = useState(0);

  useAnimatedData(ref, { threshold: 0 });

  useEffect(() => {
    const str = title ?? '';

    const timeout = setTimeout(() => {
      if (caretIndex !== str.length) {
        setText(prevState => prevState += str[caretIndex]);
      }
    }, 150);

    return () => { clearTimeout(timeout); };
  }, [caretIndex]);

  useEffect(() => {
    if (text) {
      setCaretIndex((prev) => prev += 1);
    }
  }, [text, title]);

  return (
      <Styled.Section>
          <Styled.Header>
            <Styled.Title ref={ref}>{text}</Styled.Title>
            <Spacer space={theme.spacings.x32} />
            <Text size={TextSize.M1} align={TextAlign.CENTER} color={theme.colors.base.grey[700]}>{subtitle}</Text>
          </Styled.Header>
          <Styled.PartnersGroup>
              <Partners list={[
                { image: sberLogo, label: 'Логотип Sber', href: 'https://sbertech.ru/' },
                { image: skoltechLogo, label: 'Логотип Skoltech', href: 'https://www.skoltech.ru/' },
                { image: misisLogo, label: 'Логотип MISIS', href: 'https://misis.ru/' },
                { image: yandexLogo, label: 'Логотип Yandex', href: 'https://ya.ru/' },
                { image: myProfITLogo, label: 'Логотип Моя профессия - ИТ', href: 'https://mpit.pro/' },
                { image: miaLogo, label: 'Логотип Агенство Инноваций города Москвы', href: 'https://innoagency.ru/' }
              ]} />
          </Styled.PartnersGroup>
      </Styled.Section>
  );
};
