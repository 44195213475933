import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { API_CONFIG } from '../../../../config/api';

import { type ClubsRequest, type ClubsResponse } from './types';

export const getClub = async (
  data?: ClubsRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<ClubsResponse>> => {
  const { baseUrl = '' } = options ?? {};
  const { club, ...rest } = data ?? {};

  return await request({
    url: '/api/clubs?populate[1]=head.avatar&populate[2]=aboutImage&populate[3]=goalImage&populate[4]=subHead.avatar',
    baseUrl,
    method: HTTPMethods.GET,
    headers: {
      Authorization: API_CONFIG.API_TOKEN
    },
    params: {
      ...rest,
      'filters[club][$eq]': club
    }
  });
};
