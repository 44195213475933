import React, { type FC, useMemo, useState } from 'react';

import { useTheme } from 'styled-components';

import { Flex, Heading, Spacer, Text } from '@uiKit';

import { FlexJustifyContent } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';
import { Table } from '@uiKit/Table';
import { TableBody, TableCell, TableRow } from '@uiKit/Table/components';
import { TextSize } from '@uiKit/Text/types';

import { type AchievementsProps } from '@component/AchievementsSection/types';
import { RadioGroup } from '@component/RadioGroup';
import { RadioText } from '@component/RadioText';
import { useBreakpoint } from '@hooks/useBreakpoint';

export const AchievementsSection: FC<AchievementsProps> = ({ achievements }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);
  const [selectedYear, setSelectedYear] = useState(achievements ? achievements[0].year : 0);

  const onChange = (value: string) => { setSelectedYear(Number(value)); };

  const years = useMemo(() => new Set(achievements?.map(v => v.year)), [achievements]);

  return (
    <section>
      <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER} color={theme.colors.base.light}>Достижения</Heading>
      <Spacer space={isMobile ? theme.spacings.x16 : theme.spacings.x24} />
      <Flex justifyContent={FlexJustifyContent.CENTER}>
        <RadioGroup name='year' value={selectedYear} onChange={onChange} gap={theme.spacings.x36}>
          {achievements ? Array.from(years)?.map((year, index) => (
            <RadioText value={year} key={index}>
              {year}
            </RadioText>
          )) : <></>}
        </RadioGroup>
      </Flex>
      <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
      <Table rowSpace={theme.spacings.x24} fullWidth aria-label='Достижения за выбранный год'>
        <TableBody>
          {achievements?.filter(v => v.year === selectedYear)?.map(({ title, text }, index) => (
            <TableRow key={index}>
              <TableCell>
                <Text size={TextSize.ML} fontWeight={600} color={theme.colors.base.grey[700]}>{title}</Text>
              </TableCell>
              <TableCell align={isMobile ? undefined : 'right'}>
                <Text size={isMobile ? TextSize.M2 : TextSize.ML} fontWeight={600} color={theme.colors.base.light}>{text}</Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </section>
  );
};
