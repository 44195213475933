import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { MobileHeader, DesktopHeader } from '@component/Header/components';
import { useBreakpoint } from '@hooks/useBreakpoint';

export const Header: FC = () => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  return isMobile ? <MobileHeader /> : <DesktopHeader />;
};
