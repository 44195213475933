import styled from 'styled-components';

import { Text as _Text } from '@uiKit';

const Text = styled(_Text)`
  line-height: initial;
`;

export const Styled = {
  Text
};
