import { type ImageProps } from '@uiKit/Image/types';

export enum ClubCardDir {
  RTL = 'rtl',
  LTR = 'ltr'
}

export interface ClubCardProps {
  title?: string
  tags?: string[]
  dir?: ClubCardDir
  color: string
  images: ImageProps['images']
  imagesHover: ImageProps['images']
}
