import styled from 'styled-components';

const ScrollElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.x32}px;

  animation: primary 45s linear infinite;

  @keyframes primary {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(calc(-100% - ${({ theme }) => theme.spacings.x32}px));
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    animation: primary 35s linear infinite;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.x32}px;
  left: calc(-1 * var(--layout-padding-side));
  width: calc(100% + var(--layout-padding-side) + var(--layout-padding-side));
  overflow: hidden;
  opacity: 0;
  transform: scale(.75);
  animation-play-state: paused;
  transition: .75s;
  
  &[data-animated='true'] {
    opacity: 1;
    transform: scale(1);
    animation-play-state: running;
  }

  &:hover ${ScrollElement}, &:focus-within ${ScrollElement} {
    animation-play-state: paused;
  }
`;

const ShadowStart = styled.div`
  position: absolute;
  left: 0;
  width: 100px;
  height: 100%;
  z-index: 1;
  background-image: linear-gradient(90deg, ${({ theme }) => theme.colors.base.background} 30%,rgba(252,255,116,0) 99.99%);
`;

const ShadowEnd = styled.div`
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  z-index: 1;
  background-image: linear-gradient(270deg, ${({ theme }) => theme.colors.base.background} 30%,rgba(252,255,116,0) 99.99%);
`;

export const Styled = {
  Wrapper,
  ScrollElement,
  ShadowStart,
  ShadowEnd
};
