import styled, { css } from 'styled-components';

import {
  type TeamImageProps,
  TeamImageSize
} from '@pages/Home/components/TeamSection/components/TeamImage/declarations';

export const getTeamImageSizeCSS = {
  [TeamImageSize.SM]: css`
    width: 270px;
    height: 270px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      width: 160px;
      height: 160px;
    }
  `,
  [TeamImageSize.LG]: css`
    width: 270px;
    height: 375px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      width: 160px;
      height: 240px;
    }
  `
};

const Image = styled.img<{
  $size: TeamImageProps['size']
}>`
  border-radius: ${({ theme }) => theme.radiuses.sm}px;
  object-fit: cover;
  
  ${({ $size }) => getTeamImageSizeCSS[$size as TeamImageSize]}
`;

export const Styled = {
  Image
};
