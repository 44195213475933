import { createBrowserRouter } from 'react-router-dom';

import { homeRoutes, projectsRoutes, newsRoutes, teamRoutes, clubsRoutes } from './routes';

export const router = createBrowserRouter([
  ...homeRoutes,
  ...projectsRoutes,
  ...newsRoutes,
  ...teamRoutes,
  ...clubsRoutes
]);
