import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Heading, Spacer, Text } from '@uiKit';

import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';
import { TextAlign, TextSize } from '@uiKit/Text/types';

import { useBreakpoint } from '@hooks/useBreakpoint';
import { PartnersBlock } from '@pages/Home/components/PartnersSection/component';
import { Styled } from '@pages/Home/components/PartnersSection/styled';
import { type PartnersSectionProps } from '@pages/Home/components/PartnersSection/types';

export const PartnersSection: FC<PartnersSectionProps> = ({ subtitle }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  return (
    <section id='partners'>
      <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER} color={theme.colors.base.light}>Партнеры</Heading>
      <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
      <PartnersBlock />
      <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
      <Styled.PartnerInfoWrapper>
        <Styled.PartnerInfo>
          <Text align={isMobile ? TextAlign.CENTER : undefined} size={isMobile ? TextSize.S3 : TextSize.M1} color={theme.colors.base.grey[700]}>
            {subtitle}
          </Text>
        </Styled.PartnerInfo>
      </Styled.PartnerInfoWrapper>
    </section>
  );
};
