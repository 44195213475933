import styled from 'styled-components';

const Nav = styled.nav``;

const LinkList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  gap: ${({ theme }) => theme.spacings.x60}px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings.x36}px;
    align-items: center;
  }
`;

const LinkListItem = styled.li``;

export const Styled = {
  LinkList,
  LinkListItem,
  Nav
};
