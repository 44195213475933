import { type RefObject, useEffect } from 'react';

import { useIntersectionObserver } from '@hooks/useIntersectionObserver';

export const useAnimatedData = (ref: RefObject<any>, options?: IntersectionObserverInit, saveOnHide?: boolean) => {
  const isVisible = useIntersectionObserver(ref, options ?? { threshold: 0 });

  useEffect(() => {
    if (saveOnHide) {
      if (isVisible) {
        ref.current?.setAttribute('data-animated', 'true');
      }

      return;
    }

    ref.current?.setAttribute('data-animated', String(isVisible));
  }, [isVisible]);
};
