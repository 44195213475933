import { CLUBS_ROUTE } from '@router/routes/clubs/constants';
import { HOME_ROUTE } from '@router/routes/home/constants';
import { NEWS_ROUTE } from '@router/routes/news/constants';
import { PROJECTS_ROUTE } from '@router/routes/projects/constants';
import { TEAM_ROUTE } from '@router/routes/team/constants';

export const ROUTES = {
  ...HOME_ROUTE,
  ...PROJECTS_ROUTE,
  ...NEWS_ROUTE,
  ...TEAM_ROUTE,
  ...CLUBS_ROUTE
};
