import React, { type FC, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Spacer } from '@uiKit';

import { AchievementsSection } from '@component/AchievementsSection';
import { LoaderScreen } from '@component/LoaderScreen';
import { useAPI } from '@hooks/useAPI';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { PageLayout } from '@layouts/PageLayout';
import {
  getAchievements,
  getHome,
  getNews,
  getProjects,
  getSocial,
  getTeam,
  getInfoBlocks,
  getMainClubsInfo
} from '@lib/api';
import { getPartnersBlock } from '@lib/api/rest/partners-block';
import { getBaseImage } from '@lib/baseImage/getBaseImage';
import {
  AboutSection,
  ClubsSection,
  MainSection,
  PartnersSection,
  ProjectsSection, SocialSection,
  StatisticsSection, TeamSection
} from '@pages/Home/components';

export const Home: FC = () => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  const location = useLocation();

  useEffect(() => {
    location.hash && document.querySelector(location.hash)?.scrollIntoView();
  }, [location.hash]);

  const { data, isLoading } = useAPI({ apiService: getHome }, { runOnMount: true });
  const { data: infoBlocks, isLoading: isLoadingInfoBlocks } = useAPI({ apiService: getInfoBlocks }, { runOnMount: true });
  const { data: partnersBlock, isLoading: isLoadingPartnerBlock } = useAPI({ apiService: getPartnersBlock }, { runOnMount: true });
  const { data: mainClubs, isLoading: isLoadingMainClubs } = useAPI({ apiService: getMainClubsInfo }, { runOnMount: true });
  const { data: projects, isLoading: isLoadingProjects } = useAPI({ apiService: getProjects, requestData: { count: 4 } }, { runOnMount: true });
  const { data: news, isLoading: isLoadingNews } = useAPI({ apiService: getNews, requestData: { count: 3 } }, { runOnMount: true });
  const { data: achievements, isLoading: isLoadingAchievements } = useAPI({ apiService: getAchievements, requestData: { count: 5 } }, { runOnMount: true });
  const { data: team, isLoading: isLoadingTeam } = useAPI({ apiService: getTeam }, { runOnMount: true });
  const { data: social, isLoading: isLoadingSocial } = useAPI({ apiService: getSocial }, { runOnMount: true });

  const projectsData = useMemo(() => projects?.data.map(({ title, image, badge }) => ({ title, tag: badge, image: image.formats.medium.url })), [projects?.data]);
  const newsData = useMemo(() => news?.data.map(({ title, image, sources, url }) => ({ title, sources, image: getBaseImage(image.url), url })), [news?.data]);
  const teamData = useMemo(() => team?.data.map(({ name, image, post }) => ({ name, image: getBaseImage(image.url), post })), [team?.data]);

  const isLoadingData = isLoading || isLoadingProjects || isLoadingNews || isLoadingAchievements || isLoadingTeam || isLoadingSocial || isLoadingInfoBlocks || isLoadingPartnerBlock || isLoadingMainClubs;
  const getStatisticsTransformedData = (section: string) => {
    const data = infoBlocks?.data.find(v => v.section === section);
    const { title = '', subtitle = '' } = data ?? {};
    return { title, subtitle };
  };

  const getClubsTransformedData = (clubName: string) => {
    const data = mainClubs?.data.find(v => v.ref === clubName);
    const { name = '', tags = '' } = data ?? {};
    return { title: name, tags: tags.split(',') };
  };

  return (
    isLoadingData ? <LoaderScreen /> : (
      <PageLayout>
        <MainSection title={data?.data?.title} subtitle={data?.data?.subtitle} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <StatisticsSection
          clubsOne={getStatisticsTransformedData('clubsOne')}
          clubsTwo={getStatisticsTransformedData('clubsTwo')}
          welcomeSection={getStatisticsTransformedData('welcome')}
          projectOne={getStatisticsTransformedData('projectOne')}
          projectTwo={getStatisticsTransformedData('projectTwo')}
          quote={getStatisticsTransformedData('quote')}
        />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <ProjectsSection projects={projectsData} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <PartnersSection subtitle={partnersBlock?.data.desc} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <ClubsSection
          hackClub={getClubsTransformedData('hack')}
          roboticsClub={getClubsTransformedData('robotics')}
          gamedevClub={getClubsTransformedData('gamedev')}
          designClub={getClubsTransformedData('design')}
          aiClub={getClubsTransformedData('ai')}
        />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <AboutSection news={newsData} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <AchievementsSection achievements={achievements?.data} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <TeamSection team={teamData} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <SocialSection social={social?.data} />
        <Spacer space={isMobile ? theme.spacings.x128 : theme.spacings.x128 * 2} />
      </PageLayout>
    )
  );
};
