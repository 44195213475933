import styled from 'styled-components';

import { Flex } from '@uiKit';

const Footer = styled.footer`
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: ${({ theme }) => `0 var(--layout-padding-side) ${theme.spacings.x64}px var(--layout-padding-side)`};
  gap: 16px;
  min-height: 150px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings.x24}px;
  }
`;

const CreatorsBlock = styled(Flex)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
  }
`;

export const Styled = {
  Wrapper,
  CreatorsBlock,
  Footer
};
