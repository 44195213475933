export const COLORS = {
  BASE_COLORS: {
    PRIMARY: '#FFFFFF',
    ACENT: '#FFFFFF',
    SECONDARY: '#90908F',
    GREY: {
      100: '#F9F9FA',
      200: '#FAFAFE',
      300: '#EEEEEE',
      350: '#BCBBC5',
      400: '#808080',
      700: '#8A8989',
      800: '#424140',
      900: '#2a2a2a'
    },
    BACKGROUND: '#1E1D1C',
    LIGHT: '#FFFFFF',
    DARK: '#000000'
  },
  ADDITIONAL_COLORS: {
    FOCUS: 'rgba(145,210,255, 0.8)',
    BRAND_PURPLE: '#FF8DFF',
    ORANGE_GRADIENT: 'linear-gradient(78deg, #FF6186 0%, #FFA067 51.04%, #CF51FF 100%)',
    RED_GRADIENT: 'linear-gradient(90deg, #FF8DFF 0%, #FF4668 100%)',
    BLUE_GRADIENT: 'linear-gradient(90deg, #E6C2FF 0%, #7DC7FF 100%)',
    GREEN_GRADIENT: 'linear-gradient(90deg, #00ED6D 0%, #00FDD6 100%)',
    YELLOW_GRADIENT: 'linear-gradient(90deg, #FF649A 0%, #FFE000 100%)',
    PURPLE_GRADIENT: 'linear-gradient(90deg, #D16CFD 0%, #FF66D1 100%)',
    ORANGE_GRADIENT_FLOW: 'linear-gradient(78deg, #2a2a2a 20%, #FF6186, #FFA067, #CF51FF)',
    RED_GRADIENT_FLOW: 'linear-gradient(90deg, #2a2a2a 20%, #FF8DFF, #FF4668)',
    BLUE_GRADIENT_FLOW: 'linear-gradient(90deg, #2a2a2a 20%, #FF6186, #FFA067, #CF51FF)',
    GREEN_GRADIENT_FLOW: 'linear-gradient(90deg, #2a2a2a 20%, #FF6186, #FFA067, #CF51FF)',
    YELLOW_GRADIENT_FLOW: 'linear-gradient(90deg, #2a2a2a 20%, #FF6186, #FFA067, #CF51FF)',
    PURPLE_GRADIENT_FLOW: 'linear-gradient(90deg, #2a2a2a 20%, #FF6186, #FFA067, #CF51FF)'
  }
};
