import styled from 'styled-components';

import { Card as _Card } from '@component/Card';

const Card = styled(_Card)`
  padding: clamp(${({ theme }) => theme.spacings.x16}px, 3vw, ${({ theme }) => theme.spacings.x28}px);
`;

export const Styled = {
  Card
};
