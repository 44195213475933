import React, { type FC, type PropsWithChildren } from 'react';

import { useTheme } from 'styled-components';

import { Flex, Heading, Spacer, Text } from '@uiKit';

import { FlexDirection } from '@uiKit/Flex/types';
import { HeadingType } from '@uiKit/Heading/types';

import { Styled } from '@component/ProjectCard/styled';
import { type ProjectCardProps } from '@component/ProjectCard/types';

import { FILE_CONFIG } from '../../config/files';

export const ProjectCard: FC<PropsWithChildren<ProjectCardProps>> = ({ title, tag, imageLabel, images }) => {
  const theme = useTheme();

  return (
    <Styled.Card>
      <Styled.Image baseUrl={FILE_CONFIG.BASE_URL} fullWidth radius={theme.radiuses.df} alt={imageLabel} images={images} />
      <Spacer space={theme.spacings.x24} />
      <Flex direction={FlexDirection.COLUMN}>
        <Heading type={HeadingType.H3} color={theme.colors.base.light}>{title}</Heading>
        <Spacer space={theme.spacings.x64} />
        {tag && <Text color={theme.colors.base.grey[700]}>{tag}</Text>}
      </Flex>
    </Styled.Card>
  );
};
