import React, { type FC, useRef } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Flex, Heading, Icon, Link, Spacer, Text } from '@uiKit';

import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';
import { Images } from '@uiKit/Icon/constants/images';
import { IconSize } from '@uiKit/Icon/types';
import { TextAlign, TextSize } from '@uiKit/Text/types';

import { Highlight } from '@component/index';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Team, TeamImage, TeamProfile } from '@pages/Home/components/TeamSection/components';
import { TeamImageSize } from '@pages/Home/components/TeamSection/components/TeamImage/declarations';
import { type TeamSectionProps } from '@pages/Home/components/TeamSection/types';
import { ROUTES } from '@router/routes/constants';

export const TeamSection: FC<TeamSectionProps> = ({ team }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);
  const ref = useRef<HTMLElement>(null);

  const moreLink = (
    <Link as={RouterLink} isUnderline to={ROUTES.team.path}>
      <Text size={TextSize.M1}>О нас</Text>
      <Spacer space={theme.spacings.x16} />
      <Icon size={isMobile ? IconSize.X16 : IconSize.X20} image={Images.ARROW_RIGHT} />
    </Link>
  );

  return (
    <section ref={ref}>
      <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER} color={theme.colors.base.light}>Команда</Heading>
      <Spacer space={theme.spacings.x16} />
      {isMobile && <Text align={TextAlign.CENTER} size={TextSize.S3} color={theme.colors.base.grey[700]}><Highlight color={theme.colors.additional.brand_purple}>ITAM</Highlight> — ИТ комьюнити нового поколения, состоящее из мотивированных и талантливых участников.</Text>}
      <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
      <Team>
        {team?.map(({ name, post, image }, index) => <TeamProfile key={index} style={{ marginTop: `${(index + 1) % 2 === 0 ? 54 : (index + 1) % 3 === 0 ? 108 : 0}px` }} image={<TeamImage image={image} size={(index + 1) % 3 === 0 ? TeamImageSize.LG : TeamImageSize.SM} />} name={name} subtitle={post} />)}
      </Team>
      {isMobile && (
        <>
          <Spacer space={theme.spacings.x36} />
          <Flex justifyContent={FlexJustifyContent.CENTER}>
            {moreLink}
          </Flex>
        </>
      )}
      {!isMobile && (
        <>
          <Spacer space={theme.spacings.x128} />
          <Flex direction={FlexDirection.ROW} alignItems={FlexAlignItems.CENTER} justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
            <Spacer space={0} />
            <Text style={{ maxWidth: '700px' }} size={TextSize.ML} color={theme.colors.base.grey[700]}><Highlight color={theme.colors.additional.brand_purple}>ITAM</Highlight> — ИТ комьюнити нового поколения, состоящее из мотивированных и талантливых участников.</Text>
            {moreLink}
          </Flex>
        </>
      )}
    </section>
  );
};
