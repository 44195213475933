import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { API_CONFIG } from '../../../../config/api';

import { type TeamResponse, type TeamRequest } from './types';

export const getTeam = async (
  data?: TeamRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<TeamResponse>> => {
  const { baseUrl = '' } = options ?? {};

  return await request({
    url: '/api/team?populate=*',
    headers: {
      Authorization: API_CONFIG.API_TOKEN
    },
    baseUrl,
    method: HTTPMethods.GET,
    params: {
      ...data
    }
  });
};
