import React, { type FC } from 'react';

import { NavLink } from 'react-router-dom';

import { Link } from '@uiKit';

import { LinkSize } from '@uiKit/Link/types';

import { ROUTES } from '@router/routes/constants';

import { Styled } from './styled';

export const FooterNav: FC = () => {
  return (
    <Styled.Nav>
      <Styled.LinkList>
        <Styled.LinkListItem>
          <Link size={LinkSize.MD} as={NavLink} to={ROUTES.projects.path}>Проекты</Link>
        </Styled.LinkListItem>
        <Styled.LinkListItem>
          <Link size={LinkSize.MD} as={NavLink} to={ROUTES.team.path}>Команда</Link>
        </Styled.LinkListItem>
      </Styled.LinkList>
    </Styled.Nav>
  );
};
