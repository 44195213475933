import React, { type FC, useRef } from 'react';

import { useTheme } from 'styled-components';

import { Heading, Spacer } from '@uiKit';

import { FlexDirection } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';

import aiImagePNG from '@assets/images/clubs/ai/ai.png';
import aiImageWebP from '@assets/images/clubs/ai/ai.webp';
import aiImageHoverPNG from '@assets/images/clubs/ai/ai_hover.png';
import aiImageHoverWebP from '@assets/images/clubs/ai/ai_hover.webp';
import designImagePNG from '@assets/images/clubs/design/design.png';
import designImageWebP from '@assets/images/clubs/design/design.webp';
import designImageHoverPNG from '@assets/images/clubs/design/design_hover.png';
import designImageHoverWebP from '@assets/images/clubs/design/design_hover.webp';
import gamedevImagePNG from '@assets/images/clubs/gamedev/gamedev.png';
import gamedevImageWebP from '@assets/images/clubs/gamedev/gamedev.webp';
import gamedevImageHoverPNG from '@assets/images/clubs/gamedev/gamedev_hover.png';
import gamedevImageHoverWebP from '@assets/images/clubs/gamedev/gamedev_hover.webp';
import hackImagePNG from '@assets/images/clubs/hack/hack.png';
import hackImageWebP from '@assets/images/clubs/hack/hack.webp';
import hackImageHoverPNG from '@assets/images/clubs/hack/hack_hover.png';
import hackImageHoverWebP from '@assets/images/clubs/hack/hack_hover.webp';
import roboticsImagePNG from '@assets/images/clubs/robotics/robotics.png';
import roboticsImageWebP from '@assets/images/clubs/robotics/robotics.webp';
import roboticsImageHoverPNG from '@assets/images/clubs/robotics/robotics_hover.png';
import roboticsImageHoverWebP from '@assets/images/clubs/robotics/robotics_hover.webp';
import { ClubCard } from '@component/ClubCard';
import { ClubCardDir } from '@component/ClubCard/types';
import { useAnimatedData } from '@hooks/useAnimatedData';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Styled } from '@pages/Home/components/ClubsSection/styled';
import { type ClubsSectionProps } from '@pages/Home/components/ClubsSection/types';
import { ROUTES } from '@router/routes/constants';

export const ClubsSection: FC<ClubsSectionProps> = ({ hackClub, designClub, gamedevClub, aiClub, roboticsClub }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  const ref = useRef<HTMLDivElement>(null);
  const refSecond = useRef<HTMLDivElement>(null);
  const refThird = useRef<HTMLDivElement>(null);
  const refFourth = useRef<HTMLDivElement>(null);
  const refFifth = useRef<HTMLDivElement>(null);

  useAnimatedData(ref);
  useAnimatedData(refSecond);
  useAnimatedData(refThird);
  useAnimatedData(refFourth);
  useAnimatedData(refFifth);

  return (
    <section id='clubs'>
      <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER} color={theme.colors.base.light}>Клубы коммьюнити</Heading>
      <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
      <Styled.ClubsWrapper direction={FlexDirection.COLUMN} gap={theme.spacings.x32}>
        {hackClub && (
          <Styled.CardLink to={ROUTES.hackathon.path}>
            <Styled.AnimatedClubsCard ref={ref}>
              <ClubCard images={[{ type: 'webp', image: hackImageWebP, isPreferable: true }, { type: 'png', image: hackImagePNG }]} imagesHover={[{ type: 'webp', image: hackImageHoverWebP, isPreferable: true }, { type: 'png', image: hackImageHoverPNG }]} color={theme.colors.additional.orange_gradient} title={hackClub?.title} tags={hackClub?.tags} />
            </Styled.AnimatedClubsCard>
          </Styled.CardLink>
        )}
        {designClub && (
          <Styled.CardLink to={ROUTES.design.path}>
            <Styled.AnimatedClubsCard ref={refSecond}>
              <ClubCard images={[{ type: 'webp', image: designImageWebP, isPreferable: true }, { type: 'png', image: designImagePNG }]} imagesHover={[{ type: 'webp', image: designImageHoverWebP, isPreferable: true }, { type: 'png', image: designImageHoverPNG }]} color={theme.colors.additional.purple_gradient} title={designClub?.title} tags={designClub?.tags} dir={ClubCardDir.RTL} />
            </Styled.AnimatedClubsCard>
          </Styled.CardLink>
        )}
        {aiClub && (
          <Styled.CardLink to={ROUTES.ai.path}>
            <Styled.AnimatedClubsCard ref={refThird}>
              <ClubCard images={[{ type: 'webp', image: aiImageWebP, isPreferable: true }, { type: 'png', image: aiImagePNG }]} imagesHover={[{ type: 'webp', image: aiImageHoverWebP, isPreferable: true }, { type: 'png', image: aiImageHoverPNG }]} color={theme.colors.additional.green_gradient} title={aiClub?.title} tags={aiClub?.tags} />
            </Styled.AnimatedClubsCard>
          </Styled.CardLink>
        )}
        {gamedevClub && (
          <Styled.CardLink to={ROUTES.game.path}>
            <Styled.AnimatedClubsCard ref={refFourth}>
              <ClubCard images={[{ type: 'webp', image: gamedevImageWebP, isPreferable: true }, { type: 'png', image: gamedevImagePNG }]} imagesHover={[{ type: 'webp', image: gamedevImageHoverWebP, isPreferable: true }, { type: 'png', image: gamedevImageHoverPNG }]} color={theme.colors.additional.blue_gradient} title={gamedevClub?.title} tags={gamedevClub?.tags} dir={ClubCardDir.RTL} />
            </Styled.AnimatedClubsCard>
          </Styled.CardLink>
        )}
        {roboticsClub && (
          <Styled.CardLink to={ROUTES.robotics.path}>
            <Styled.AnimatedClubsCard ref={refFifth}>
              <ClubCard images={[{ type: 'webp', image: roboticsImageWebP, isPreferable: true }, { type: 'png', image: roboticsImagePNG }]} imagesHover={[{ type: 'webp', image: roboticsImageHoverWebP, isPreferable: true }, { type: 'png', image: roboticsImageHoverPNG }]} color={theme.colors.additional.red_gradient} title={roboticsClub?.title} tags={roboticsClub?.tags} />
            </Styled.AnimatedClubsCard>
          </Styled.CardLink>
        )}
      </Styled.ClubsWrapper>
    </section>
  );
};
