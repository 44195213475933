import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Flex, Spacer } from '@uiKit';

import { FlexDirection } from '@uiKit/Flex/types';
import { TextSize } from '@uiKit/Text/types';

import { Styled } from '@component/SocialLinkBlock/styled';
import { type SocialLinkBlockProps } from '@component/SocialLinkBlock/types';

export const SocialLinkBlock: FC<SocialLinkBlockProps> = ({ socialName, socialUsername, href }) => {
  const theme = useTheme();

  return (
    <Styled.Link href={href}>
      <Styled.Block>
        <Flex direction={FlexDirection.COLUMN}>
          <Styled.SocialName size={TextSize.M2}>{socialName}</Styled.SocialName>
          <Spacer space={theme.spacings.x4} />
          <Styled.SocialUsername size={TextSize.M1} color={theme.colors.base.grey[700]}>{socialUsername}</Styled.SocialUsername>
        </Flex>
        <Styled.GoArrow />
      </Styled.Block>
    </Styled.Link>
  );
};
