import arrowRight from '../../../../assets/icons/arrow_right.svg';
import gh from '../../../../assets/icons/gh.svg';
import goArrow from '../../../../assets/icons/go_arrow.svg';
import menu from '../../../../assets/icons/menu.svg';
import notion from '../../../../assets/icons/notion.svg';
import tg from '../../../../assets/icons/tg.svg';
import vk from '../../../../assets/icons/vk.svg';
import { Images } from '../../constants/images';

export const getImage = (image: Images): string | undefined => {
  switch (image) {
    case Images.ARROW_RIGHT:
      return arrowRight;
    case Images.GO_ARROW:
      return goArrow;
    case Images.TG:
      return tg;
    case Images.VK:
      return vk;
    case Images.GH:
      return gh;
    case Images.NOTION:
      return notion;
    case Images.MENU:
      return menu;
    default:
      return undefined;
  }
};
