import React, { Children, type FC, type PropsWithChildren, useRef } from 'react';

import { Styled } from '@component/ProjectList/styled';
import { useAnimatedData } from '@hooks/useAnimatedData';

export const ProjectList: FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLUListElement>(null);

  useAnimatedData(ref, { threshold: 0 });

  return (
    <Styled.List ref={ref}>
      {Children.map(children, (child) => <Styled.Item>{child}</Styled.Item>)}
    </Styled.List>
  );
};
