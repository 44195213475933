import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Divider, Flex, Spacer, Text } from '@uiKit';

import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types';
import { Images } from '@uiKit/Icon/constants/images';

import { FooterNav } from '@component/Footer/components';
import { FooterCreatorBlock } from '@component/Footer/components/FooterCreatorBlock/FooterCreatorBlock';
import { Styled } from '@component/Footer/styled';
import { IconLink } from '@component/IconLink';
import { IconLinkSize } from '@component/IconLink/types';
import { Logo } from '@component/Logo';
import { useBreakpoint } from '@hooks/useBreakpoint';

export const Footer: FC = () => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  return (
    <Styled.Footer>
      <Divider />
      <Spacer space={theme.spacings.x64} />
      <Styled.Wrapper>
        <Flex alignItems={isMobile ? FlexAlignItems.CENTER : undefined} gap={theme.spacings.x16} direction={FlexDirection.COLUMN} justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
          <Logo />
          <Text>2023</Text>
        </Flex>
        <Flex justifyContent={isMobile ? FlexJustifyContent.CENTER : undefined}>
          <FooterNav />
        </Flex>
        {isMobile && (
          <Flex justifyContent={FlexJustifyContent.CENTER} gap={theme.spacings.x8}>
            <IconLink to='' icon={Images.VK} size={IconLinkSize.LG} />
            <IconLink to='' icon={Images.TG} size={IconLinkSize.LG} />
          </Flex>
        )}
        <Styled.CreatorsBlock gap={isMobile ? theme.spacings.x24 : theme.spacings.x64}>
          <FooterCreatorBlock subtitle='Frontend' name='Слава Додонов' links={[{ image: Images.GH, href: 'https://github.com/SlDo' }, { image: Images.TG, href: 'https://t.me/sl_do' }]} />
          <FooterCreatorBlock subtitle='Design' name='Илья Брегман' links={[{ image: Images.NOTION, href: 'https://notion.com' }]} />
        </Styled.CreatorsBlock>
      </Styled.Wrapper>
    </Styled.Footer>
  );
};
