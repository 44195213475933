import styled from 'styled-components';

const PartnerInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr;
  }
`;

const PartnerInfo = styled.div`
  grid-column-start: 2;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-column-start: 1;
  }
`;

export const Styled = {
  PartnerInfoWrapper,
  PartnerInfo
};
