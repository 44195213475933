import styled, { css } from 'styled-components';

import { type RadioGroupProps } from '@component/RadioGroup/types';

const Fieldset = styled.fieldset<{
  $direction: RadioGroupProps['direction']
  $gap: RadioGroupProps['gap']
}>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  ${({ $gap }) => $gap && css`
    gap: ${$gap}px;
  `};
  line-height: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  border-width: 0;
`;

export const Styled = {
  Fieldset
};
