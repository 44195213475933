import styled from 'styled-components';

import { Text } from '@uiKit';

import { type GradientTextProps } from '@component/GradientText/types';

const MainText = styled(Text)<{
  $color: GradientTextProps['color']
}>`
  background: ${({ $color }) => $color};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Styled = {
  MainText
};
