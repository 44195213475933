import { type ImageProps } from '@uiKit/Image/types';

export enum NewsCardDir {
  RTL = 'rtl',
  LTR = 'ltr'
}

export interface NewsCardProps {
  sources: string[]
  title: string
  images: ImageProps['images']
  dir?: NewsCardDir
  url: string
}
