import React, { type FC, useRef } from 'react';

import { useTheme } from 'styled-components';

import { Heading, Spacer } from '@uiKit';

import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';

import { SocialLinkBlock } from '@component/SocialLinkBlock';
import { useAnimatedData } from '@hooks/useAnimatedData';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Styled } from '@pages/Home/components/SocialSection/styled';
import { type SocialSectionProps } from '@pages/Home/components/SocialSection/types';

export const SocialSection: FC<SocialSectionProps> = ({ social }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);
  const ref = useRef<HTMLDivElement>(null);

  useAnimatedData(ref, { threshold: 0.25 });

  return (
    <section>
      <Heading type={HeadingType.H2} size={isMobile ? HeadingSize.DF : HeadingSize.LG} align={HeadingAlign.CENTER} color={theme.colors.base.light}>Наши соцсети</Heading>
      <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
      <Styled.SocialLinksWrapper ref={ref}>
        {social?.map(({ title, username, URL }, index) => <SocialLinkBlock key={index} socialName={title} socialUsername={username} href={URL} />)}
      </Styled.SocialLinksWrapper>
    </section>
  );
};
