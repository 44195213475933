import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Flex } from '@uiKit';

const CardLink = styled(Link)`
  text-decoration: none;
`;

const AnimatedClubsCard = styled.div`
  &:nth-of-type(odd)  {
    transform: translateX(-200px);
  }

  &:nth-of-type(even) {
    transform: translateX(200px);
  }
  
  &[data-animated='true'] {
    &:nth-of-type(odd)  {
      animation: .5s oddAnimationCard linear forwards;
    }

    &:nth-of-type(even) {
      animation: .75s evenAnimationCard linear forwards;
    }
  }

  @keyframes oddAnimationCard {
    to {
      transform: translateX(0);
    }
  }

  @keyframes evenAnimationCard {
    to {
      transform: translateX(0);
    }
  }
`;

const ClubsWrapper = styled(Flex)``;

export const Styled = {
  ClubsWrapper,
  AnimatedClubsCard,
  CardLink
};
