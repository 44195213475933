import styled, { css } from 'styled-components';

import { type HighlightProps } from '@component/Highlight/types';

const Highlight = styled.strong<{
  $color: HighlightProps['color']
}>`
  ${({ $color }) => $color && css`
    color: ${$color};
  `}
`;

export const Styled = {
  Highlight
};
