import React from 'react';

import { type RouteObject } from 'react-router-dom';

import { Club } from '@pages/Clubs';

import { CLUBS_ROUTE } from './constants';

export const clubsRoutes: RouteObject[] = [
  {
    path: CLUBS_ROUTE.club.path,
    element: (
        <Club />
    )
  }
];
