import styled, { css } from 'styled-components';

import { StatisticsCard } from '@pages/Home/components/StatisticsSection/components';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  gap: ${({ theme }) => theme.spacings.x16}px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-rows: 1fr;
  }
`;

const TeamImage = styled.div`
  img {
    opacity: 0;
    transition: 1s;
    transition-delay: .25s;
    transform: scale(0);
    max-height: 800px;
    aspect-ratio: 3 / 2;
  }

  &[data-animated='true'] {
    img {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const animateSectionCardInitial = css`
  opacity: 0;
  transition: 1s;
`;

const WelcomeSectionCard = styled(StatisticsCard)`
  grid-area: welcome;
  
  ${animateSectionCardInitial};
`;

const Project30SectionCard = styled(StatisticsCard)`
  grid-area: project30;

  ${animateSectionCardInitial};
`;

const Project35SectionCard = styled(StatisticsCard)`
  grid-area: project35;
  
  ${animateSectionCardInitial};
`;

const QuoteSectionCard = styled(StatisticsCard)`
  grid-area: quote;

  ${animateSectionCardInitial};
`;

const ClubsSectionCard = styled(StatisticsCard)`
  grid-area: clubs;

  ${animateSectionCardInitial};
`;

const CoursesSectionCard = styled(StatisticsCard)`
  grid-area: courses;

  ${animateSectionCardInitial};
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, minmax(100px, 1fr));
  grid-template-areas: 
            "welcome welcome quote quote"
            "project30 project35 quote quote"
            "project30 project35 clubs courses";
  gap: ${({ theme }) => theme.spacings.x16}px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas: 
            "welcome welcome"
            "project30 project35"
            "quote quote"
            "clubs courses";
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_small}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, min-content);
    grid-template-areas: 
            "welcome"
            "project30" 
            "project35"
            "quote"
            "clubs" 
            "courses";
  }

  ${[WelcomeSectionCard, Project35SectionCard, Project30SectionCard, QuoteSectionCard, ClubsSectionCard, CoursesSectionCard].map((StyledComponent, index) => css`
    ${StyledComponent} {
      &[data-animated="true"] {
        animation: .25s animateWelcomeSectionCard linear forwards;
        animation-delay: ${0.15 * index}s; 
      }
    }
  `)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile_small}px) {
    ${[WelcomeSectionCard, Project30SectionCard, Project35SectionCard, QuoteSectionCard, ClubsSectionCard, CoursesSectionCard].map((StyledComponent, index) => css`
    ${StyledComponent} {
      &[data-animated="true"] {
        animation: .25s animateWelcomeSectionCard linear forwards;
        animation-delay: ${0.15 * index}s; 
      }
    }
  `)};
  }

  ${WelcomeSectionCard} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }

  ${Project35SectionCard}, ${Project30SectionCard} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  ${ClubsSectionCard}, ${CoursesSectionCard} {
    p {
      width: 100%;
      text-align: center;
    }
  }

  @keyframes animateSectionCard {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes animateWelcomeSectionCard {
    from {
      opacity: 0;
      transform: scale(0) translate3d(0px, -100%, 0px);
    }
    
    to {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0);
    }
  }
`;

export const Styled = {
  Section,
  Wrapper,
  WelcomeSectionCard,
  Project30SectionCard,
  Project35SectionCard,
  QuoteSectionCard,
  CoursesSectionCard,
  ClubsSectionCard,
  TeamImage
};
