import styled from 'styled-components';

import { Heading } from '@uiKit';

import { type GradientTextProps } from '@component/GradientText/types';

const MainHeading = styled(Heading)<{
  $color: GradientTextProps['color']
}>`
  background: ${({ $color }) => $color};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Styled = {
  MainHeading
};
