import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { API_CONFIG } from '../../../../config/api';

import { type NewsRequest, type NewsResponse } from './types';

export const getNews = async (
  data?: NewsRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<NewsResponse>> => {
  const { baseUrl = '' } = options ?? {};
  const { count, ...rest } = data ?? {};

  return await request({
    url: '/api/all-news?populate=*',
    baseUrl,
    method: HTTPMethods.GET,
    headers: {
      Authorization: API_CONFIG.API_TOKEN
    },
    params: {
      ...rest,
      'pagination[limit]': count
    }
  });
};
