import { useCallback, useEffect, useRef, useState } from 'react';

import { API_CONFIG } from '../../config/api';

import { type UseAPIData, type UseAPIOptions } from './types';

export const useAPI = <T, G>(
  { apiService, requestData }: UseAPIData<T, G>,
  options?: UseAPIOptions
) => {
  const { runOnMount = false } = options ?? {};

  const [isLoading, setIsLoading] = useState(runOnMount);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<G | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const isSentOnMount = useRef(false);

  const runRequest = useCallback((data?: T) => {
    setIsLoading(true);

    apiService(data, {
      baseUrl: API_CONFIG.BASE_URL
    })
      .then((res) => {
        setIsError(false);
        setIsLoading(false);
        setData(res.data);
      })
      .catch((e) => {
        // Error Handler
        setErrorMessage(e);
      });
  }, []);

  useEffect(() => {
    if (runOnMount && !isSentOnMount.current) {
      isSentOnMount.current = true;

      setIsLoading(true);
      runRequest(requestData);
    }
  }, [runRequest, runOnMount, requestData]);

  return { data, isError, isLoading, errorMessage, runRequest };
};
