import styled from 'styled-components';

import { Image as _Image } from '@uiKit';

const Image = styled(_Image)`
  aspect-ratio: 1 / 1;
  width: 100%;
`;

export const Styled = {
  Image
};
