import React, { type FC } from 'react';

import { type PartnersProps } from '@pages/Home/components/MainSection/components/Partners/declarations';
import { Styled } from '@pages/Home/components/MainSection/components/Partners/styled';

export const Partners: FC<PartnersProps> = ({ list }) => {
  return (
      <Styled.PartnerList>
        <Styled.ShadowStart />
        <Styled.ScrollElement>
          {list.map(({ href, label, image }, index) => (
            <Styled.PartnerListItem key={index}>
              <a href={href}>
                <Styled.PartnerListItemImage alt={label} src={image} />
              </a>
            </Styled.PartnerListItem>
          ))}
        </Styled.ScrollElement>
        <Styled.ScrollElementTwo aria-hidden={true}>
          {list.map(({ href, label, image }, index) => (
            <Styled.PartnerListItem key={index}>
              <a href={href}>
                <Styled.PartnerListItemImage alt={label} src={image} />
              </a>
            </Styled.PartnerListItem>
          ))}
        </Styled.ScrollElementTwo>
        <Styled.ShadowEnd />
      </Styled.PartnerList>
  );
};
