import React from 'react';

import { type RouteObject } from 'react-router-dom';

import { Projects } from '@pages/Projects';

import { PROJECTS_ROUTE } from './constants';

export const projectsRoutes: RouteObject[] = [
  {
    path: PROJECTS_ROUTE.projects.path,
    element: (
        <Projects />
    )
  }
];
