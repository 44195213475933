import React, { type FC, type PropsWithChildren } from 'react';

import { useTheme } from 'styled-components';

import { TextSize } from '@uiKit/Text/types';

import { type RadioTextProps } from '@component/RadioText/types';

import { Styled } from './styled';

export const RadioText: FC<PropsWithChildren<RadioTextProps>> = ({ children, name, value, checked, ...props }) => {
  const theme = useTheme();

  return (
    <label>
      <Styled.RadioInput {...props} checked={checked} name={name} value={value} type='radio' />
      <Styled.RadioText fontWeight={600} size={TextSize.M1} color={theme.colors.base.grey[800]}>
        {children}
      </Styled.RadioText>
    </label>
  );
};
