import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Flex, Spacer, Text } from '@uiKit';

import { FlexDirection } from '@uiKit/Flex/types';
import { TextSize } from '@uiKit/Text/types';

import { type TeamProfileProps } from './types';

export const TeamProfile: FC<TeamProfileProps> = ({ image, subtitle, name, ...props }) => {
  const theme = useTheme();

  return (
    <Flex {...props} direction={FlexDirection.COLUMN}>
      {image}
      <Spacer space={theme.spacings.x12} />
      <Text size={TextSize.M1} fontWeight={600}>{name}</Text>
      <Spacer space={theme.spacings.x4} />
      <Text color={theme.colors.base.grey[700]} fontWeight={500}>{subtitle}</Text>
    </Flex>
  );
};
