import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Flex, Spacer } from '@uiKit';

import { FlexAlignItems, FlexDirection } from '@uiKit/Flex/types';
import { TextSize } from '@uiKit/Text/types';

import { Styled } from '@component/Footer/components/FooterCreatorBlock/styled';
import { type FooterCreateBlockProps } from '@component/Footer/components/FooterCreatorBlock/types';
import { IconLink } from '@component/IconLink';
import { IconLinkSize } from '@component/IconLink/types';
import { useBreakpoint } from '@hooks/useBreakpoint';

export const FooterCreatorBlock: FC<FooterCreateBlockProps> = ({ subtitle, name, links }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  return (
    <Flex alignItems={isMobile ? FlexAlignItems.CENTER : undefined} direction={FlexDirection.COLUMN}>
      <Flex alignItems={isMobile ? FlexAlignItems.CENTER : FlexAlignItems.FLEX_START} direction={isMobile ? FlexDirection.COLUMN_REVERSE : FlexDirection.COLUMN}>
        <Styled.Text size={TextSize.S3} color={theme.colors.base.grey[700]}>{subtitle}</Styled.Text>
        <Spacer space={isMobile ? theme.spacings.x4 : theme.spacings.x24} />
        <Styled.Text size={TextSize.M1}>{name}</Styled.Text>
      </Flex>
      <Spacer space={isMobile ? theme.spacings.x16 : theme.spacings.x36} />
      <Flex direction={FlexDirection.ROW} gap={theme.spacings.x8}>
        {links.map(({ href, image }, index) => <IconLink size={IconLinkSize.MD} key={index} icon={image} to={href} />)}
      </Flex>
    </Flex>
  );
};
