import styled from 'styled-components';

import { Text } from '@uiKit';

const RadioText = styled(Text)`
  cursor: pointer;
  transition: color .25s linear;
  outline: none;

  &:hover, &:focus-visible {
    color: ${({ theme }) => theme.colors.base.light};
  }
`;

const RadioInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  
  &:checked + ${RadioText}, &:focus-visible + ${RadioText} {
    color: ${({ theme }) => theme.colors.base.light};
  }
`;

export const Styled = {
  RadioInput,
  RadioText
};
