import React, { type FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Flex, Heading, Icon, Spacer, Text } from '@uiKit';

import { ButtonSize, ButtonVariant } from '@uiKit/Button/types';
import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';
import { Images } from '@uiKit/Icon/constants/images';
import { IconSize } from '@uiKit/Icon/types';
import { ImageObjectFit } from '@uiKit/Image/types';
import { TextSize } from '@uiKit/Text/types';

import { AchievementsSection } from '@component/AchievementsSection';
import { LoaderScreen } from '@component/LoaderScreen';
import { ProjectCard } from '@component/ProjectCard';
import { ProjectList } from '@component/ProjectList';
import { useAPI } from '@hooks/useAPI';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useRedirect } from '@hooks/useRedirect';
import { PageLayout } from '@layouts/PageLayout';
import { getAchievements, getClub, getProjects } from '@lib/api';
import { getBaseImage } from '@lib/baseImage/getBaseImage';
import { ClubsHead } from '@pages/Clubs/components/ClubsHead/ClubsHead';
import { type Club as IClub, getClubImage } from '@pages/Clubs/helpers';
import { ROUTES } from '@router/routes/constants';

import { Styled } from './styled';

export const Club: FC = () => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);
  const { redirect } = useRedirect();

  const { name } = useParams();

  const { data, isLoading } = useAPI({ apiService: getClub, requestData: { club: name } }, { runOnMount: true });
  const { data: projects, isLoading: isLoadingProjects } = useAPI({ apiService: getProjects, requestData: { club: name } }, { runOnMount: true });
  const { data: achievements, isLoading: isLoadingAchievements } = useAPI({ apiService: getAchievements, requestData: { club: name } }, { runOnMount: true });

  const { title, inviteUrl, about, aboutImage, goalImage, goal, head, subHead } = data?.data?.[0] ?? {};
  const { png, webp, webpHover, pngHover } = getClubImage((name ?? 'hack') as IClub);

  useEffect(() => {
    if (data?.data.length === 0) redirect(ROUTES.home.path);
  }, [data?.data]);

  return (
    isLoading || isLoadingProjects || isLoadingAchievements ? <LoaderScreen /> : (
      data?.data.length !== 0 && (
        <PageLayout>
          <Spacer space={theme.spacings.x128 + theme.spacings.x128} />
          <Styled.HeadWrapper>
            <Styled.Heading align={HeadingAlign.CENTER}>{title}</Styled.Heading>
            <Styled.ImageWrapper>
              <Styled.Image objectFit={ImageObjectFit.CONTAIN} fullWidth fullHeight alt='Хакатон клуб' images={[{ type: 'webp', image: webpHover, isPreferable: true }, { type: 'png', image: pngHover }]} />
              <Styled.ImageFront objectFit={ImageObjectFit.CONTAIN} fullWidth fullHeight alt='Хакатон клуб' images={[{ type: 'webp', image: webp, isPreferable: true }, { type: 'png', image: png }]} />
            </Styled.ImageWrapper>
          </Styled.HeadWrapper>
          <Spacer space={theme.spacings.x128 + theme.spacings.x128} />
          <Flex direction={FlexDirection.COLUMN} gap={isMobile ? theme.spacings.x64 : theme.spacings.x128}>
            <Flex direction={isMobile ? FlexDirection.COLUMN_REVERSE : FlexDirection.ROW} gap={isMobile ? theme.spacings.x32 : theme.spacings.x128}>
              <Flex justifyContent={FlexJustifyContent.CENTER} flex='1' direction={FlexDirection.COLUMN}>
                <Heading type={HeadingType.H3} size={HeadingSize.MD}>О нас</Heading>
                <Spacer space={theme.spacings.x24} />
                <Text size={TextSize.M1} color={theme.colors.base.grey['400']}>
                  {about}
                </Text>
              </Flex>
              {aboutImage && (
                <Flex flex='1' alignItems={FlexAlignItems.CENTER}>
                  <Styled.ContainerImage radius={theme.radiuses.md} fullWidth fullHeight alt='Hack' images={[{ type: 'webp', image: getBaseImage(aboutImage.url), isPreferable: true }]} />
                </Flex>
              )}
            </Flex>
            <Flex direction={isMobile ? FlexDirection.COLUMN : FlexDirection.ROW} gap={isMobile ? theme.spacings.x32 : theme.spacings.x128}>
              {goalImage && (
                <Flex flex='1'>
                  <Styled.ContainerImage radius={theme.radiuses.md} fullWidth fullHeight alt='Hack' images={[{ type: 'webp', image: getBaseImage(goalImage.url), isPreferable: true }]} />
                </Flex>
              )}
              <Flex justifyContent={FlexJustifyContent.CENTER} flex='1' direction={FlexDirection.COLUMN}>
                <Heading type={HeadingType.H3} size={HeadingSize.MD}>Цель</Heading>
                <Spacer space={theme.spacings.x24} />
                <Text size={TextSize.M1} color={theme.colors.base.grey['400']}>
                  {goal}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Spacer space={theme.spacings.x128} />
          {projects?.data && projects?.data?.length > 0 ? (
            <>
              <section>
                <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER}>Проекты</Heading>
                <Spacer space={theme.spacings.x128} />
                <ProjectList>
                  {projects?.data.map(({ image, title, badge }, index) => <ProjectCard key={index} images={[{ type: 'webp', image: image.url }]} title={title} tag={badge} />)}
                </ProjectList>
              </section>
              <Spacer space={theme.spacings.x128} />
            </>
          ) : <></>}
          {achievements?.data?.length !== 0 && (
            <>
              <AchievementsSection achievements={achievements?.data} />
              <Spacer space={theme.spacings.x128} />
            </>
          )}
          <section>
            <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER}>Руководители</Heading>
            <Spacer space={theme.spacings.x128} />
            <Flex gap={theme.spacings.x128} alignItems={FlexAlignItems.CENTER} direction={FlexDirection.COLUMN} justifyContent={FlexJustifyContent.CENTER}>
              <Flex direction={isMobile ? FlexDirection.COLUMN : FlexDirection.ROW} fullWidth gap={theme.spacings.x24} justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
                {head && <ClubsHead image={getBaseImage(head.avatar.url)} subtitle={head.post} name={head.name} socialLinks={[{ icon: Images.TG, href: head.url }]} />}
                {subHead && <ClubsHead image={getBaseImage(subHead.avatar.url)} subtitle={subHead.post} name={subHead.name} socialLinks={[{ icon: Images.TG, href: subHead.url }]} />}
              </Flex>
              <Styled.InviteLink href={inviteUrl} variant={ButtonVariant.PRIMARY} size={ButtonSize.LG}>
                <Flex gap={theme.spacings.x24}>
                  Вступить в клуб
                  <Icon size={IconSize.X20} image={Images.ARROW_RIGHT} />
                </Flex>
              </Styled.InviteLink>
            </Flex>
          </section>
          <Spacer space={theme.spacings.x128} />
        </PageLayout>
      )
    )
  );
};
