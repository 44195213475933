import React from 'react';

import { type RouteObject } from 'react-router-dom';

import { Home } from '@pages/Home';

import { HOME_ROUTE } from './constants';

export const homeRoutes: RouteObject[] = [
  {
    path: HOME_ROUTE.home.path,
    element: (
        <Home />
    )
  }
];
