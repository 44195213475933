import React, { type FC } from 'react';

export const Logo: FC = () => {
  return <svg width="75" height="24" viewBox="0 0 75 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H4.1267L4.1367 23.975H0.00999935L0 0Z" fill="white" fillOpacity={1} />
        <path d="M23.9047 0V3.63122H16.848L16.858 23.975H12.7313L12.7213 3.63122H5.66468V0H23.9047Z" fill="white" fillOpacity={1} />
        <path d="M56.2209 20.2839H57.4596V17.4039H58.155V0.0619348L49.3391 0.0369822V2.88601H47.9455V5.76601H46.7068V8.70795H45.6228V11.557H44.2293V14.437H42.9906V17.3789H42.2952H41.5998V20.2589H40.3611V23.975H46.2609V21.126V18.1531H48.8904V15.3041V12.455H51.3988L51.3989 9.48214H53.5765V24H56.2209V23.9501V20.2839Z" fill="white" fillOpacity={1}/>
        <path d="M62.5666 5.79097V8.7329H61.4826V11.5819H60.0891V14.4619H58.8504V17.4039H58.155H57.4596V20.2839H56.2209V23.9501L60.0891 23.975V21.151H62.1207V18.1781H64.7502V15.329V12.48H67.2586L67.2587 9.5071H68.9769V23.975H74.0148V0.0619348H72.7451V0L66.3137 0.0619348H65.1989V2.91097H63.8053V5.79097H62.5666Z" fill="white" fillOpacity={1}/>
        <path d="M22.2368 23.9751V21.4438H23.1968V19.2141H24.1878V17.1083H25.0239V14.9096H26.0458V12.7728H27.2226V9.49023H32.7738L38.4392 23.9751H34.2782L30.0097 13.0825H29.1736V15.4051H28.1517V17.5418H27.3155V19.7406H26.3555V21.4438H29.729V23.9751H25.3114H22.2368Z" fill="white" fillOpacity={1}/>
    </svg>;
};
