import styled from 'styled-components';

import { Flex, Heading, Image as _Image } from '@uiKit';

import { NewsCardDir, type NewsCardProps } from '@component/NewsCard/types';

const Link = styled.a`
  text-decoration: none;
`;

const Wrapper = styled.div<{
  $dir: NewsCardProps['dir']
}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: ${({ $dir }) => $dir === NewsCardDir.RTL ? "'image info'" : "'info image'"};
  gap: ${({ theme }) => theme.spacings.x16}px;
  
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    grid-template-areas: "image" "info";
  }
`;

const Title = styled(Heading)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Info = styled(Flex)`
  grid-area: info;
  box-sizing: border-box;
  height: 100%;
  gap: 16px;
  padding-bottom: ${({ theme }) => theme.spacings.x24}px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column-reverse;
  }
`;

const ImageWrapper = styled.div`
  grid-area: image;
`;

const Image = styled(_Image)`
  height: 450px;
  
  @media screen and (max-width: 768px) {
    height: 250px;
  }
`;

export const Styled = {
  Wrapper,
  Title,
  Info,
  Image,
  ImageWrapper,
  Link
};
