import styled from 'styled-components';

import { Image as _Image } from '@uiKit/Image';

import { Card as _Card } from '@component/Card';

import { animatedBorder } from '../../styles/animatedBorder';

const Card = styled(_Card)`
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: clamp(${({ theme }) => theme.spacings.x16}px, 3vw, ${({ theme }) => theme.spacings.x24}px);
  border: 1px solid rgba(255, 255, 255, .1);
  height: 100%;
  box-sizing: border-box;
  
  &:hover {
    ${({ theme }) => animatedBorder(theme.colors.additional.orange_gradient_flow)};
  }
`;

const Image = styled(_Image)`
  height: 250px;
`;

export const Styled = {
  Card,
  Image
};
