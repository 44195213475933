import React, { type FC, Fragment } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Divider, Flex, Heading, Icon, Link, Spacer, Text } from '@uiKit';

import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';
import { Images } from '@uiKit/Icon/constants/images';
import { IconSize } from '@uiKit/Icon/types';
import { TextSize } from '@uiKit/Text/types';

import { NewsCard } from '@component/NewsCard/NewsCard';
import { NewsCardDir } from '@component/NewsCard/types';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { type AboutSectionProps } from '@pages/Home/components/AboutSection/types';
import { ROUTES } from '@router/routes/constants';

export const AboutSection: FC<AboutSectionProps> = ({ news }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  return (
    <section>
      <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER} color={theme.colors.base.light}>Упоминание в СМИ</Heading>
      <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
      <Flex direction={FlexDirection.COLUMN}>
        {news?.map(({ title, sources, image, url }, index) => (
          <Fragment key={index}>
            {!isMobile && <>
              <Divider />
              <Spacer space={theme.spacings.x36} />
            </>}
            <NewsCard dir={index % 2 === 0 ? NewsCardDir.LTR : NewsCardDir.RTL} url={url} title={title} sources={sources.split(',')} images={[{ type: 'webp', image, isPreferable: true }]} />
            <Spacer space={theme.spacings.x36} />
            {index === news?.length - 1 && <>
              {!isMobile && <>
                <Spacer space={theme.spacings.x36} />
                <Divider />
              </>}
            </>}
          </Fragment>
        ))}
      </Flex>
      <Spacer space={theme.spacings.x48} />
      <Flex fullWidth justifyContent={FlexJustifyContent.CENTER} alignItems={FlexAlignItems.CENTER}>
        <Link as={RouterLink} isUnderline to={ROUTES.news.path}>
          <Text size={TextSize.M1}>Больше кейсов</Text>
          <Spacer space={theme.spacings.x16} />
          <Icon size={IconSize.X20} image={Images.ARROW_RIGHT} />
        </Link>
      </Flex>
    </section>
  );
};
