import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Flex, Heading, Spacer, Text } from '@uiKit';

import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types';
import { HeadingSize } from '@uiKit/Heading/types';
import { TextSize } from '@uiKit/Text/types';

import { IconLink } from '@component/IconLink';
import { IconLinkSize } from '@component/IconLink/types';
import { Styled } from '@pages/Clubs/components/ClubsHead/styled';
import { type ClubsHeadProps } from '@pages/Clubs/components/ClubsHead/types';

export const ClubsHead: FC<ClubsHeadProps> = ({ name, image, subtitle, socialLinks }) => {
  const theme = useTheme();

  return (
    <Flex direction={FlexDirection.COLUMN}>
      <Styled.Image alt='Руководитель клуба' images={[{ type: 'webp', image }]} />
      <Spacer space={theme.spacings.x16} />
      <Flex alignItems={FlexAlignItems.CENTER} direction={FlexDirection.ROW} justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
        <Flex direction={FlexDirection.COLUMN}>
          <Heading size={HeadingSize.DF}>{name}</Heading>
          <Text size={TextSize.M1} color={theme.colors.base.grey['400']}>{subtitle}</Text>
        </Flex>
        <Flex>
          {socialLinks?.map(({ icon, href }, index) => href ? <IconLink key={index} size={IconLinkSize.LG} icon={icon} to={href} /> : <></>)}
        </Flex>
      </Flex>
    </Flex>
  );
};
