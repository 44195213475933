import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { API_CONFIG } from '../../../../config/api';

import { type AchievementsRequest, type AchievementsResponse } from './types';

export const getAchievements = async (
  data?: AchievementsRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<AchievementsResponse>> => {
  const { baseUrl = '' } = options ?? {};
  const { count, year, club } = data ?? {};

  return await request({
    url: '/api/achievements',
    baseUrl,
    method: HTTPMethods.GET,
    headers: {
      Authorization: API_CONFIG.API_TOKEN
    },
    params: {
      'pagination[limit]': count,
      'filters[year][$eq]': year,
      'filters[club][$eq]': club
    }
  });
};
