export const CLUBS_ROUTE = {
  club: {
    path: '/clubs/:name'
  },
  hackathon: {
    path: '/clubs/hack'
  },
  design: {
    path: '/clubs/design'
  },
  robotics: {
    path: '/clubs/robotics'
  },
  game: {
    path: '/clubs/game'
  },
  ai: {
    path: '/clubs/ai'
  }
};
