import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Flex } from '@uiKit';

import { Badge } from '@uiKit/Badge';
import { BadgeVariant } from '@uiKit/Badge/types';
import { FlexAlignItems, FlexDirection, FlexJustifyContent, FlexWrap } from '@uiKit/Flex/types';
import { HeadingSize, HeadingType } from '@uiKit/Heading/types';

import { Styled } from '@component/NewsCard/styled';
import { NewsCardDir, type NewsCardProps } from '@component/NewsCard/types';

export const NewsCard: FC<NewsCardProps> = ({ sources, title, dir = NewsCardDir.LTR, images, url }) => {
  const theme = useTheme();

  return (
    <Styled.Link href={url}>
      <Styled.Wrapper $dir={dir}>
        <Styled.Info fullHeight direction={FlexDirection.COLUMN} alignItems={FlexAlignItems.FLEX_START} justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
          <Flex direction={FlexDirection.ROW} flexWrap={FlexWrap.WRAP} gap={theme.spacings.x16}>
            {sources?.map((source, index) => <Badge key={index} variant={BadgeVariant.SECONDARY} color={theme.colors.base.light}>{source}</Badge>)}
          </Flex>
          <Styled.Title color={theme.colors.base.light} type={HeadingType.H4} size={HeadingSize.DF}>{title}</Styled.Title>
        </Styled.Info>
        <Styled.ImageWrapper>
          <Styled.Image fullWidth radius={theme.radiuses.sm} alt='Новости' images={images} />
        </Styled.ImageWrapper>
      </Styled.Wrapper>
    </Styled.Link>
  );
};
