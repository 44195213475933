import React, { type FC, useRef } from 'react';

import { useTheme } from 'styled-components';

import { Flex, Image, Spacer, Text } from '@uiKit';

import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types';
import { TextAlign, TextSize } from '@uiKit/Text/types';

import backImageWebP from '@assets/images/back_image.webp';
import { GradientText } from '@component/GradientText';
import { useAnimatedData } from '@hooks/useAnimatedData';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { BREAKPOINTS } from '@lib/theme/constants';
import { type StatisticsSectionProps } from '@pages/Home/components/StatisticsSection/types';

import { Styled } from './styled';

export const StatisticsSection: FC<StatisticsSectionProps> = ({ welcomeSection, projectOne, projectTwo, quote, clubsTwo, clubsOne }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(BREAKPOINTS.MOBILE);
  const imageWrapperRef = useRef<HTMLDivElement>(null);

  const cardFirstRef = useRef<HTMLDivElement>(null);
  const cardSecondRef = useRef<HTMLDivElement>(null);
  const cardThirdRef = useRef<HTMLDivElement>(null);
  const cardFourthRef = useRef<HTMLDivElement>(null);
  const cardFifthRef = useRef<HTMLDivElement>(null);
  const cardSixthRef = useRef<HTMLDivElement>(null);

  useAnimatedData(imageWrapperRef, undefined, true);

  useAnimatedData(cardFirstRef, undefined, true);
  useAnimatedData(cardSecondRef, undefined, true);
  useAnimatedData(cardThirdRef, undefined, true);
  useAnimatedData(cardFourthRef, undefined, true);
  useAnimatedData(cardFifthRef, undefined, true);
  useAnimatedData(cardSixthRef, undefined, true);

  return (
      <Styled.Wrapper id='about'>
          <Styled.Section>
              <Styled.WelcomeSectionCard ref={cardFirstRef}>
                {welcomeSection?.title && <GradientText size={TextSize.L3} fontWeight={800} color={theme.colors.additional.red_gradient}>{welcomeSection?.title}</GradientText>}
                {welcomeSection?.subtitle && <Text size={TextSize.M1} align={TextAlign.END} color={theme.colors.base.grey[700]}>{welcomeSection?.subtitle}</Text>}
              </Styled.WelcomeSectionCard>
              <Styled.Project30SectionCard ref={cardSecondRef}>
                {projectOne?.title && (
                  <>
                    <GradientText size={TextSize.G2} fontWeight={800} color={theme.colors.additional.blue_gradient} align={TextAlign.CENTER}>{projectOne?.title}</GradientText>
                    <Spacer space={theme.spacings.x16} />
                  </>
                )}
                {projectOne?.title && (
                  <Text size={TextSize.M1} color={theme.colors.base.grey[700]} align={TextAlign.CENTER}>{projectOne?.subtitle}</Text>
                )}
              </Styled.Project30SectionCard>
              <Styled.Project35SectionCard ref={cardThirdRef}>
                {projectTwo?.title && (
                  <>
                    <GradientText size={TextSize.G2} fontWeight={800} color={theme.colors.additional.green_gradient} align={TextAlign.CENTER}>{projectTwo?.title}</GradientText>
                    <Spacer space={theme.spacings.x16} />
                  </>
                )}
                {projectTwo?.subtitle && (
                  <>
                    <Text size={TextSize.M1} color={theme.colors.base.grey[700]} align={TextAlign.CENTER}>{projectTwo?.subtitle}</Text>
                  </>
                )}
              </Styled.Project35SectionCard>
              <Styled.QuoteSectionCard ref={cardFourthRef}>
                  <Flex fullHeight direction={FlexDirection.COLUMN} justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
                    {quote?.title && <Text size={TextSize.M1} color={theme.colors.base.grey[700]}>{quote?.title}</Text>}
                  </Flex>
              </Styled.QuoteSectionCard>
              <Styled.ClubsSectionCard ref={cardFifthRef}>
                  <Flex fullHeight alignItems={FlexAlignItems.CENTER}>
                    {clubsOne?.title && <GradientText align={isMobile ? TextAlign.CENTER : TextAlign.START} size={TextSize.L3} fontWeight={800} color={theme.colors.additional.yellow_gradient}>{clubsOne?.title}</GradientText>}
                  </Flex>
              </Styled.ClubsSectionCard>
              <Styled.CoursesSectionCard ref={cardSixthRef}>
                  <Flex fullHeight alignItems={FlexAlignItems.CENTER}>
                    {clubsTwo?.title && <GradientText align={isMobile ? TextAlign.CENTER : TextAlign.START} size={TextSize.L3} fontWeight={800} color={theme.colors.additional.purple_gradient}>{clubsTwo?.title}</GradientText>}
                  </Flex>
              </Styled.CoursesSectionCard>
          </Styled.Section>
          {!isMobile && (
            <Styled.TeamImage ref={imageWrapperRef}>
                <Image fullWidth radius={theme.radiuses.df} images={[{ type: 'webp', image: backImageWebP, isPreferable: true }]} />
            </Styled.TeamImage>
          )}
      </Styled.Wrapper>
  );
};
