import styled from 'styled-components';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: calc(100vh - 80px);
  margin: 80px 0;
  box-sizing: border-box;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    height: min-content;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const Title = styled.h1`
  text-align: center;
  background: ${({ theme }) => theme.colors.additional.orange_gradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: clamp(2rem, 10vw, 11rem);
  white-space: nowrap;
  font-style: normal;
  font-weight: 800;
  margin: 0;
  opacity: 0;
  transform: translate3d(-40px, -40px, -40px);
  transition: transform 1s ease-in;
  
  &[data-animated='true'] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const PartnersGroup = styled.div`
  width: 100%;
  margin-bottom: 80px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: ${({ theme }) => theme.spacings.x64}px;
  }
`;

export const Styled = {
  Section,
  Title,
  Header,
  PartnersGroup
};
