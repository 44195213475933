import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Flex, Spacer } from '@uiKit';

import { Badge } from '@uiKit/Badge';
import { FlexDirection, FlexJustifyContent, FlexWrap } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';
import { ImageObjectFit } from '@uiKit/Image/types';

import { Styled } from '@component/ClubCard/styled';
import { ClubCardDir, type ClubCardProps } from '@component/ClubCard/types';
import { useBreakpoint } from '@hooks/useBreakpoint';

export const ClubCard: FC<ClubCardProps> = ({ title, tags, dir = ClubCardDir.LTR, color, images, imagesHover }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  return (
      <Styled.ClubCard $color={color} $dir={dir}>
        <Styled.Main flex='1' fullHeight direction={FlexDirection.COLUMN} justifyContent={!isMobile ? FlexJustifyContent.SPACE_BETWEEN : undefined}>
            {title && <Styled.Heading align={isMobile ? HeadingAlign.CENTER : undefined} type={HeadingType.H4} size={HeadingSize.MD} color={theme.colors.base.light}>{title}</Styled.Heading>}
            {isMobile && <Spacer space={theme.spacings.x28} />}
            <Flex flexWrap={FlexWrap.WRAP} justifyContent={isMobile ? FlexJustifyContent.CENTER : undefined} gap={theme.spacings.x12}>
              {tags?.map((tag, index) => <Badge key={index}>{tag}</Badge>)}
            </Flex>
        </Styled.Main>
        <Styled.ImageWrapper>
          <Styled.Image objectFit={ImageObjectFit.CONTAIN} fullWidth fullHeight alt='Хакатон клуб' images={imagesHover} />
          <Styled.ImageFront objectFit={ImageObjectFit.CONTAIN} fullWidth fullHeight alt='Хакатон клуб' images={images} />
        </Styled.ImageWrapper>
      </Styled.ClubCard>
  );
};
