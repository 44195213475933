import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { API_CONFIG } from '../../../../config/api';

import { type PartnersBlockRequest, type PartnersBlockResponse } from './types';

export const getPartnersBlock = async (
  data?: PartnersBlockRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<PartnersBlockResponse>> => {
  const { baseUrl = '' } = options ?? {};

  return await request({
    url: 'api/partners-info',
    baseUrl,
    headers: {
      Authorization: API_CONFIG.API_TOKEN
    },
    method: HTTPMethods.GET,
    params: {
      ...data
    }
  });
};
