import React, { type FC } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Flex, Heading, Icon, Link, Spacer, Text } from '@uiKit';

import { FlexJustifyContent } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';
import { Images } from '@uiKit/Icon/constants/images';
import { IconSize } from '@uiKit/Icon/types';
import { TextSize } from '@uiKit/Text/types';

import { ProjectCard } from '@component/ProjectCard';
import { ProjectList } from '@component/ProjectList';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { type ProjectSectionProps } from '@pages/Home/components/ProjectsSection/types';
import { ROUTES } from '@router/routes/constants';

export const ProjectsSection: FC<ProjectSectionProps> = ({ projects }) => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  return (
        <section id='projects'>
          <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER} color={theme.colors.base.light}>Проекты</Heading>
          <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
          <ProjectList>
            {projects?.map(({ image, title, tag }, index) => <ProjectCard key={index} images={[{ type: 'webp', image }]} title={title} tag={tag} />)}
          </ProjectList>
          <Spacer space={theme.spacings.x48} />
          <Flex fullWidth justifyContent={FlexJustifyContent.CENTER}>
            <Link as={RouterLink} isUnderline to={ROUTES.projects.path}>
              <Text size={TextSize.M1}>Больше кейсов</Text>
              <Spacer space={theme.spacings.x16} />
              <Icon size={IconSize.X20} image={Images.ARROW_RIGHT} />
            </Link>
          </Flex>
        </section>
  );
};
