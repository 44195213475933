import styled, { css } from 'styled-components';

const scrollElementCss = css`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  white-space: nowrap;
  
  display: flex;
  align-items: center;
  box-sizing: border-box;

  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;

  transform: translateX(0);
`;

const PartnerListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScrollElement = styled.ul`
  ${scrollElementCss};
  
  animation: primary 15s linear infinite;

  @keyframes primary {
    from {
      transform: translateX(0);
    }
    
    to {
      transform: translateX(-100%);
    }
  }
`;

const ScrollElementTwo = styled.ul`
  ${scrollElementCss};

  position: absolute;
  animation: secondary 15s linear infinite;

  @keyframes secondary {
    from {
      transform: translateX(100%);
    }
    
    to {
      transform: translateX(0);
    }
  }
`;

const ShadowStart = styled.div`
  position: absolute;
  left: 0;
  width: 100px;
  height: 100%;
  z-index: 1;
  background-image: linear-gradient(90deg, ${({ theme }) => theme.colors.base.background} 30%,rgba(252,255,116,0) 99.99%);
`;

const ShadowEnd = styled.div`
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  z-index: 1;
  background-image: linear-gradient(270deg, ${({ theme }) => theme.colors.base.background} 30%,rgba(252,255,116,0) 99.99%);
`;

const PartnerList = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  
  &:hover {
    ${ScrollElement}, ${ScrollElementTwo} {
      animation-play-state: paused;
    }
  }
`;

const PartnerListItemImage = styled.img`
  height: clamp(24px, 5vw, 32px);
  width: clamp(180px, 20vw, 230px);
`;

export const Styled = {
  PartnerList,
  PartnerListItem,
  PartnerListItemImage,
  ScrollElement,
  ScrollElementTwo,
  ShadowStart,
  ShadowEnd
};
