import styled, { type IStyledComponent } from 'styled-components';

import { Image as _Image, Heading as _Heading, Button } from '@uiKit';

import { type ButtonProps } from '@uiKit/Button/types';

const Image = styled(_Image)`
  position: absolute;
`;

const ImageFront = styled(Image)`
  position: absolute;
  top: 0;
  transition: opacity .25s linear;
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 100%;
  }
`;

const ContainerImage = styled(_Image)``;

const Heading = styled(_Heading)`
  z-index: 2;
`;

const HeadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    ${ImageFront} {
      opacity: 0;
    }
  }
`;

const InviteLink = styled(Button).attrs({ forwardedAs: 'a' })`
  text-decoration: none;
` as IStyledComponent<'web', 'a', ButtonProps>;

export const Styled = {
  HeadWrapper,
  ImageWrapper,
  ImageFront,
  Image,
  Heading,
  ContainerImage,
  InviteLink
};
