import React, { type FC } from 'react';

import {
  type TeamImageProps,
  TeamImageSize
} from '@pages/Home/components/TeamSection/components/TeamImage/declarations';
import { Styled } from '@pages/Home/components/TeamSection/components/TeamImage/styled';

export const TeamImage: FC<TeamImageProps> = ({ image, size = TeamImageSize.SM }) => {
  return <Styled.Image $size={size} src={image} />;
};
